/* src/pages/JoinUs.css */

.join-us {
    padding: 2rem;
    color: #e0e0e0;
    background-color: #0d0d0d;
  }
  
  .intro h2 {
    font-size: 2.5rem;
    color: #ffffff;
    margin-bottom: 1rem;
  }
  
  .intro p {
    font-size: 1.2rem;
    color: #c0c0c0;
    line-height: 1.6;
    margin-bottom: 2rem;
  }
  
  .card {
    background-color: #1a1a1a;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
    margin-top: 1.5rem;
    transition: transform 0.2s ease;
  }
  
  .card:hover {
    transform: scale(1.02);
  }
  
  h3 {
    font-size: 1.8rem;
    color: #66a3ff;
    border-bottom: 2px solid #004080;
    padding-bottom: 0.3rem;
    margin-bottom: 1rem;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  ul li {
    font-size: 1rem;
    margin: 0.5rem 0;
    color: #b3b3b3;
  }
  
  ul li strong {
    color: #ffffff;
  }
  
  .cta-button {
    margin-top: 1.5rem;
    padding: 0.8rem 1.5rem;
    border: none;
    background-color: #004080;
    color: #ffffff;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #0059b3;
  }
  