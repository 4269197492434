.interests-page {
    padding: 2rem;
    text-align: center;
  }
  
  .interests-page h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .interests-page .intro {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  .events {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
  }
  
  .event-card {
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    text-align: left;
    overflow: hidden;
  }
  
  .event-card img {
    width: 100%;
    height: auto;
  }
  
  .event-card h3 {
    font-size: 1.5rem;
    margin: 0.5rem 1rem;
  }
  
  .event-card p {
    padding: 0 1rem 1rem;
    font-size: 1rem;
    color: #555;
  }
  