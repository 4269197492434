/* src/components/JoinUsForm.css */

.join-us-form {
  background-color: #1a1a1a; /* Dark background for the form */
  color: #e0e0e0; /* Light text color */
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
}

h2 {
  color: #ffffff; /* White color for the heading */
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #ffffff; /* White color for labels */
}

input, select, textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #004080; /* Border color for fields */
  border-radius: 4px;
  background-color: #2a2a2a; /* Dark background for input fields */
  color: #ffffff; /* White text color for inputs */
}

input::placeholder, textarea::placeholder {
  color: #c0c0c0; /* Light gray for placeholder text */
}

input:focus, select:focus, textarea:focus {
  outline: none;
  border-color: #66a3ff; /* Light blue on focus */
}

.submit-button {
  background-color: #004080; /* Dark blue for submit button */
  color: #ffffff; /* White text color for button */
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0059b3; /* Lighter blue on hover */
}
