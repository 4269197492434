.header {
  background-color: #0d0d0d;
  padding: 1.5rem;
  color: #e0e0e0;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  position: relative;
}

.header-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.logo {
  width: 80px;
  height: auto;
  margin-right: 1rem;
}

.text-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.org-name {
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  margin: 0;
}

.slogan {
  font-size: 1rem;
  color: #66a3ff;
  margin: 0;
}

.nav-links {
  margin-top: 1rem;
}

.nav-links a {
  margin: 0 1rem;
  text-decoration: none;
  color: #e0e0e0;
  font-weight: bold;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: #66a3ff;
} 

/* Language switch styling */
.language-switch {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  gap: 0.3rem;
}

.language-switch button {
  background-color: #333333;
  color: #e0e0e0;
  border: 1px solid #66a3ff;
  padding: 0.2rem 0.6rem;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
  font-size: 0.8rem;
  font-weight: bold;
}

.language-switch button:hover {
  background-color: #66a3ff;
  color: #0d0d0d;
  transform: scale(1.05);
}

.language-switch button:focus {
  outline: none;
  box-shadow: 0 0 4px #66a3ff;
}

.language-switch button.active {
  background-color: #66a3ff;
  color: #0d0d0d;
  border: 1px solid #66a3ff;
}
