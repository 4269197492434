/* src/pages/Home.css */

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #0d0d0d;
  color: #e0e0e0;
}

.home {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.hero {
  background: linear-gradient(145deg, #003366, #001a33);
  color: #ffffff;
  text-align: center;
  padding: 3rem;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
  animation: fadeIn 1.5s ease-in;
}

.hero h2 {
  font-size: 2.5rem;
  margin: 0;
  color: #e0e0e0;
}

.tagline {
  font-size: 1.2rem;
  color: #c0c0c0;
  margin-top: 0.5rem;
}

.cta-button {
  margin-top: 1.5rem;
  padding: 0.8rem 1.5rem;
  border: none;
  background-color: #004080;
  color: #ffffff;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #0059b3;
}

.card {
  background-color: #1a1a1a;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
  transition: transform 0.2s ease;
}

.card:hover {
  transform: scale(1.02);
}

.mission, .values, .get-involved {
  color: #e0e0e0;
}

.mission h3, .values h3, .get-involved h3 {
  font-size: 1.8rem;
  color: #c0d9ff;
  border-bottom: 2px solid #004080;
  padding-bottom: 0.3rem;
  margin-bottom: 1rem;
}

.values ul {
  list-style: none;
  padding: 0;
}

.values li {
  font-size: 1rem;
  margin: 0.5rem 0;
  color: #e0e0e0;
}

.values li strong {
  color: #66a3ff;
}

.get-involved p {
  color: #b3b3b3;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
