/* src/pages/Mission.css */

.mission-page {
    padding: 2rem;
    color: #e0e0e0;
    background-color: #0d0d0d;
  }
  
  .mission-intro h2 {
    font-size: 2.5rem;
    color: #ffffff;
    margin-bottom: 1rem;
  }
  
  .mission-intro p {
    font-size: 1.2rem;
    color: #c0c0c0;
    line-height: 1.6;
  }
  
  .card {
    background-color: #1a1a1a;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
    margin-top: 1.5rem;
    transition: transform 0.2s ease;
  }
  
  .card:hover {
    transform: scale(1.02);
  }
  
  h3 {
    font-size: 1.8rem;
    color: #66a3ff;
    border-bottom: 2px solid #004080;
    padding-bottom: 0.3rem;
    margin-bottom: 1rem;
  }
  
  p {
    font-size: 1rem;
    color: #b3b3b3;
    line-height: 1.6;
  }
  